<template>
    <div>
        <div
            v-for="(value) in writer.application"
            :key="value.key"
            class="writer-tab-about-writer"
        >
            <template v-if="value.value">
                <div
                    v-if="typeof value.value === 'string'"
                >
                    <h2 class="writer-tab-about-writer__introduction">
                        {{ value.key }}
                    </h2>
                    <div
                        class="writer-tab-about-writer__introduction-info"
                    >
                        <span>
                            {{ value.value }}
                        </span>
                    </div>
                </div>
                <div v-else-if="typeof value.value[0] === 'object'">
                    <h2 class="writer-tab-about-writer__introduction">
                        {{ value.key }}
                    </h2>
                    <div class="writer-tab-about-writer__introduction-wrapper-badge">
                        <a
                            v-for="file in value.value"
                            :key="file.file_id"
                            :href="file.url"
                            download
                            class="writer-tab-about-writer__introduction-badge writer-tab-about-writer__introduction-badge-file"
                        >
                            <div class="writer-tab-about-writer__introduction-file-preview">
                                <span class="writer-tab-about-writer__introduction-file-preview-format">
                                    {{ fileExtension(file.filename) }}
                                </span>
                            </div>
                            <div class="writer-tab-about-writer__introduction-file-base-info">
                                <p class="writer-tab-about-writer__introduction-file-base-info-name">
                                    {{ getFileName(file.filename) }}
                                </p>
                                <p>
                                    {{ Number(file.filesize) | file_size }}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
                <div v-else>
                    <h2 class="writer-tab-about-writer__introduction">
                        {{ value.key }}
                    </h2>
                    <div class="writer-tab-about-writer__introduction-wrapper-badge">
                        <span
                            v-for="(item, index) in value.value"
                            :key="index"
                            class="writer-tab-about-writer__introduction-badge"
                        >
                            {{ item }}
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'WriterTabAboutWriter',
    mixins: [
        filtersMixin
    ],
    props: {
        writer: {
            type: Object
        }
    },
    methods: {
        fileExtension(filename) {
            const data = filename.split('.')
            if (data[1]) return data[1]
            return false
        },
        getFileName(filename) {
            const [name, extension] = filename.split('.')
            return `${name.slice(0, 25)}.${extension}`
        }
    }
}

</script>

<style lang="scss">
    .writer-tab-about-writer{
        padding: 10px 20px;
        background-color: $white;
        &__introduction{
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 15px;
        }
        &__introduction-info{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            span {
                margin-bottom: 15px;
                display: block;
            }
        }
        &__introduction-wrapper-badge{
            display: flex;
            flex-wrap: wrap;
        }
        &__introduction-badge{
            color: $main-color;
            font-weight: 500;
            background: rgba($main-color, 0.1);
            padding: 10px 20px;
            border-radius: 16px;
            margin: 5px 15px 5px 0;
        }
        &__introduction-badge-file{
            display: flex;
            background: rgba($main-color, 0.05);
            cursor: pointer;
            &:hover{
                background: rgba($main-color, 0.1);
            }
        }
        &__introduction-file-preview {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }
        &__introduction-file-preview-format{
            display: block;
            width: 50px;
            height: 50px;
            padding-top: 28px;
            padding-left: 6px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            background: url("~@/assets/img/svg/doc_empty.svg") no-repeat center;
            background-size: cover;
            text-align-last: left;
            transform: translateX(-2px);
        }
        &__introduction-file-base-info{
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }
        &__introduction-file-base-info-name {
            font-weight: 700;
        }
    }
</style>
